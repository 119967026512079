import cloneDeep from 'lodash.clonedeep';

export function promotionMapper({products, promotions}) {
  const updatedProducts = cloneDeep(products);

  updatedProducts.forEach(product => {
    const promotion = product.primary_promotion || promotions?.find(p => p.id === product.id)?.primary_promotion;

    if (!promotion || !Object.keys(promotion).length) return;

    const percentOff = (promotion.percent_off || 0) / 100;
    const fixedOff = promotion.fixed_off_cents || 0;

    //apply to product
    const basePrice = product.price_cents;
    product.promotionPrice = basePrice ? basePrice - fixedOff - Math.floor(basePrice * percentOff) : null;
    product.promotionCode = promotion.code;

    //apply to any included discounts
    if (product.discounts?.length) {
      product.discounts.forEach(discount => {
        const dBasePrice = discount.price_per_cents;
        discount.promotionPrice = dBasePrice - fixedOff - (dBasePrice * percentOff);
        discount.promotionOff = fixedOff + Math.floor(dBasePrice * percentOff);
      });
    }

    //apply to option values
    if (promotion.include_options && product.options) {
      product.options.forEach(option => {
        option.option_values?.forEach(optionValue => {
          const ovBasePrise = optionValue.price_cents;
          optionValue.promotionPrice = ovBasePrise ? ovBasePrise - fixedOff - Math.floor(ovBasePrise * percentOff) : null;
        });
      });
    }

    //apply to product sizes
    if (product.sizes) {
      product.sizes.forEach(size => {
        const sizeBasePrice = size.price_cents;
        size.promotionPrice = sizeBasePrice ? sizeBasePrice - fixedOff - Math.floor(sizeBasePrice * percentOff) : null;

        if (promotion.include_options && size.print_options && size.print_options.length) {
          size.print_options.forEach(option => {
            option.print_option_values?.forEach(optionValue => {
              const ovBasePrise = optionValue.price_cents;
              optionValue.promotionPrice = ovBasePrise ? ovBasePrise - fixedOff - Math.floor(ovBasePrise * percentOff) : null;
            });
          });
        }
      });
    }
  });

  return updatedProducts;
}
