import {
  LayoutModalAmbassador,
  LayoutModalHelpCenter,
  LayoutModalPeopleWelcome,
  LayoutModalFaceRecUpsell
} from '#components';

function openAmbassadorModal() {
  useFModal({
    id: 'ambassador',
    size: 'md',
    component: LayoutModalAmbassador
  }).open();
}

function openHelpModal() {
  useFModal({
    id: 'help-center',
    size: 'xl',
    component: LayoutModalHelpCenter
  }).open();
}

function openPeopleWelcomeModal() {
  useFModal({
    id: 'people-welcome',
    size: 'lg',
    clickToClose: false,
    escToClose: false,
    component: LayoutModalPeopleWelcome
  }).open();
}

function openFaceRecUpsellModal() {
  useFModal({
    id: 'facerec',
    size: 'lg',
    component: LayoutModalFaceRecUpsell
  }).open();
}

export function useCommonModals() {
  return {
    openAmbassadorModal,
    openHelpModal,
    openFaceRecUpsellModal,
    openPeopleWelcomeModal
  };
}
