<template>
  <div>
    <core-modal-header>Your Ambassador</core-modal-header>
    <core-modal-body>
      <div class="flex gap-5" v-if="amb">
        <div class="flex-grow-0">
          <u-avatar
            :src="amb.avatar_url"
            :alt="amb.name"
            size="4xl"
            :ui="{background: getProfileClassNameFromUUID(amb.user_id)}"
          />
        </div>
        <div class="flex-1">
          <p class="uppercase text-gray-400 text-sm font-semibold">{{amb.achieved_rank}}</p>
          <h3 class="font-serif text-3xl text-charcoal break break-words">{{amb.name}}</h3>
          <p v-if="amb.city && amb.region" class="uppercase font-semibold text-base text-gray-500">{{amb.city}}, {{amb.region}}</p>
          <ul class="list-unstyled text-gray-600 flex flex-col gap-3 mt-5">
            <template v-for="item in contactLinks">
              <li v-if="amb[item.key]"  class="flex items-center gap-3 leading-none break-words">
                <u-icon class="text-xl" :name="item.icon" />
                <a class="underline-on-hover text-charcoal" :href="item.href" target="_blank" rel="noopener">{{item.text || amb[item.key]}}</a>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </core-modal-body>

    <core-modal-footer>
      <u-button :to="EXTERNAL_URLS.settings.ambassador" :external="true" class="no-underline">Manage Ambassador</u-button>
      <u-button variant="outline" :to="EXTERNAL_URLS.becomeAmbassador" :external="true" class="no-underline">Become an Ambassador</u-button>
    </core-modal-footer>
  </div>

</template>

<script setup>
  const sessionStore = useSessionStore();
  const {currentUser, userStoreInfo} = storeToRefs(sessionStore);

  //AMBASSADOR MODAL
  const amb = computed(() => userStoreInfo?.value?.ambassador);
  const contactLinks = computed(() => ([
    {
      key: 'email',
      icon: 'i-ri-mail-line',
      href: `mailto:${amb.value.email}`
    },
    {
      key: 'phone',
      icon: 'i-ri-phone-line',
      href: `tel:${amb.value.phone}`
    },
    {
      key: 'website',
      icon: 'i-ri-earth-line',
      href: amb.value.website,
      text: 'Website'
    },
    {
      key: 'facebook_url',
      icon: 'i-ri-facebook-box-fill',
      href: amb.value.facebook_url,
      text: 'Facebook'
    },
    {
      key: 'twitter_handle',
      icon: 'i-ri-twitter-x-line',
      href: amb.value.twitter_handle,
      text: 'Follow on X'
    },
    {
      key: 'instagram_url',
      icon: 'i-ri-instagram-line',
      href: amb.value.instagram_url,
      text: 'Follow on Instagram'
    }
  ]));
</script>
