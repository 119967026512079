<template>
  <div :class="containerClass">
    <h1 class="text-center font-serif text-3xl mb-7">Explore FOREVER<sup>&reg;</sup> Facial Recognition</h1>

    <div class="grid grid-cols-8 gap-6">
      <div class="col-span-8 sm:col-span-4"><img alt="Facial Recognition" :src="faceRecImage"></div>

      <div class="col-span-8 sm:col-span-4">
        <h3 class="font-semibold text-xl text-center sm:text-left"><span class="text-gradient-blue">Upgrade Your FOREVER Account</span></h3>
        <p class="mb-5 text-center sm:text-left">Unlock more with FOREVER Facial Recognition.</p>
        <div class="grid grid-cols-2 gap-3 mb-2" v-if="oneYearProduct && permanentProduct">

          <div class="border border-gray-300 rounded-lg p-2 flex flex-col  gap-6 justify-between items-center cursor-pointer hover:shadow hover:border-heroblue transition-all" role="button" @click="gotoFaceRecPlans">
            <p class="font-semibold">Subscription</p>

            <p class="flex flex-col items-center">
              <core-price-display
                :base-price="oneYearProduct.price_cents"
                :discounted-price="oneYearProduct.promotionPrice"
                class="text-2xl leading-tight"
                smaller-discount
                stacked
              />
              <span class="text-gray-500 text-sm text-center leading-none">per year</span>
            </p>

            <u-button color="charcoal" block>Select</u-button>
          </div>

          <div class="border border-gray-300 rounded-lg p-2 flex flex-col gap-6 justify-between items-center cursor-pointer hover:shadow hover:border-heroblue transition-all" role="button" @click="gotoFaceRecPlans">
            <p class="font-semibold text-center">One-Time Payment</p>
            <core-price-display
              :base-price="permanentProduct.price_cents"
              :discounted-price="permanentProduct.promotionPrice"
              class="text-2xl"
              smaller-discount
              stacked
            />
            <u-button block>Select</u-button>
          </div>

        </div>

        <p class="text-center">
          <u-link :to="EXTERNAL_URLS.facialRecognition" external class="text-xs text-gray-500 no-underline hover:underline">Learn More</u-link>
        </p>
      </div>
    </div>

    <p class="mt-8 text-center text-gray-600">Take your memories to the next level! Instantly discover the people &
      faces in your photos, making it effortless to organize and relive your cherished moments. Spend less time
      searching and more time enjoying your special memories.</p>
  </div>
</template>

<script setup>
  import faceRecImage from '@/assets/images/face-rec.png';
  import {EXTERNAL_URLS} from '~/constants/external-urls.js';

  const props = defineProps({
    containerClass: {
      type: String,
      default: 'p-5 sm:p-10'
    }
  });

  function gotoFaceRecPlans() {
    window.location.href = EXTERNAL_URLS.facialRecognitionPlans;
  }

  const {data, refresh, pending, error} = await useAsyncData(
    'facialRecProducts',
    () => Promise.all([
      usePricingApi().getGroupProducts({groupSlug: 'facial-recognition-products'}),
      usePricingApi().getGroupPromotions({groupSlug: 'facial-recognition-products'})
    ]),
    {
      server: false
    }
  );

  const [products, promotions] = data.value || [];
  let oneYearProduct = ref(null);
  let permanentProduct = ref(null);

  if (products) {
    const mappedProducts = promotionMapper({products, promotions});
    oneYearProduct.value = mappedProducts.find(product => product.sku === 'FACIAL-RECOG-YR-001');
    permanentProduct.value = mappedProducts.find(product => product.sku === 'FACIAL-RECOG-LF-001');
  }

</script>
