<template>
  <div class="p-5 xs:p-7 sm:p-14">

    <div class="flex justify-center mb-6">
      <h1 class="text-3xl font-semibold text-gradient-blue inline-block text-center">Welcome to People!</h1>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-12 gap-10">

      <div :class="mePerson ? 'col-span-8' : 'col-span-7'">
        <template v-if="currentUser.isPerson">
          <p class="mb-5">Use the People section to organize and celebrate the lives of those who matter most, starting with you. </p>
          <p class="mb-5">We've created your first ‘person' based on the details you provided during setup. This person is all about you! Start adding photos, videos, and memories to build your personal story. </p>
          <p class="mb-5">Add additional people and start telling their stories too!</p>
        </template>
        <template v-else>
          <p class="mb-5">Use the People section to organize and celebrate the stories of those within your organization—whether they're family members, players, teammates, associates, or colleagues.</p>
          <p class="mb-5">Start by creating 'people,' adding photos, videos, and memories to tell their stories and preserve their legacy.</p>
        </template>
        <p v-if="currentUser.can.recognizeFaces"><strong>With your Facial Recognition subscription</strong>, the system is already at work behind the scenes—finding and organizing the people in your photos.</p>
        <p v-else>
          <strong>Want to make it even easier?</strong> <a href="#" @click.prevent="openFaceRecUpsellModal" class="color-heroblue underline inline">Upgrade to unlock Facial Recognition</a> and let FOREVER automatically find and organize the people in your photos.
        </p>
      </div>

      <div class="hidden sm:block col-span-4" :class="mePerson ? 'col-span-4' : 'col-span-5'">
        <div class="border-2 rounded border-gray-200 p-6" v-if="mePerson">
          <people-list-item :person="mePerson" disable-edit show-file-count />
        </div>
        <img v-else :src="peopleWelcomeOrg" />
      </div>
    </div>

    <div class="mt-8 flex items-center flex-col justify-center">
      <u-button
        size="xl"
        @click="dismiss"
        label="Get Started"
        block
        class="max-w-64"
      />
    </div>

  </div>
</template>

<script setup>

  import peopleWelcomeOrg from '~/assets/images/people-welcome-org.png';
  const {openFaceRecUpsellModal} = useCommonModals();

  const props = defineProps({
    modal: Object
  });

  const peopleStore = usePeopleStore();
  const {people} = storeToRefs(peopleStore);

  const mePerson = computed(() => people?.value?.find(person => person.isCurrentUser));

  const sessionStore = useSessionStore();
  const {currentUser} = storeToRefs(sessionStore);

  function dismiss() {
    props.modal.close();
    usePreferencesStore().updateCurrentUserPreferences({[CURRENT_USER_PREFERENCES.onbPeopleDismissed]: true});
  }
</script>

